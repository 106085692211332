import { useFormik } from "formik";
import { useEffect, useState } from "react";

// material
import {
  Container,
  Grid,
  Stack,
  CircularProgress,
} from "@mui/material";

import AlertDialogSlide from "src/components/SlideInDialog";
import CreateClientForm from "src/components/CreateClientForm";
import { apiFunctions } from "src/api";
import Page from "src/components/Page";
import { AdminDashboardCommonData } from "./commons/admin-dashboard-common-data";
import AdminCustomerInformationTable from "./tables/customer-information";


export default function AdminCustomerInformation() {
  const [loading, setLoading] = useState(false);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const getPlans = async (page, rowsPerPage) => {
    try {
      setLoading(true);
      const res = await apiFunctions.getAminCustomerInformationReport(page, rowsPerPage);
      setData(res?.data);
      setTotalRows(res?.total);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleDataExport = async () => {
    await apiFunctions.downloadExcelFile(
      'admin-client/reports/customers/export', 'Customers'
    )
  }

  useEffect(() => {
    getPlans(page + 1, rowsPerPage);
  }, [page, rowsPerPage]);

  return (
    <Page title="Clients">
      <Container maxWidth="xl">
        <AdminDashboardCommonData exportData={handleDataExport}/>
        <Grid sx={{ mt: 3 }}>
          {loading ? (
            <Grid item xs={12} md={12} lg={12}>
              <Stack
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 10,
                }}
              >
                <CircularProgress />
              </Stack>
            </Grid>
          ) : (
            <AdminCustomerInformationTable 
              plans={data}
              totalRows={totalRows}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          )}
        </Grid>
        {showFormDialog && (
          <AlertDialogSlide
            isOpen={showFormDialog}
            isClose={() => setShowFormDialog(false)}
            dialogComponent={<CreateClientForm />}
          />
        )}
      </Container>
    </Page>
  );
}
