// material
import {
    Card,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Table,
    Button,
    Stack,
    Pagination,
    Grid
} from "@mui/material";
import GeneralHeader from "src/sections/@dashboard/user/GeneralHeader";
import { useState } from "react";
import { formatDate, formatTime } from "src/utils/formatTime";
import TablePaginationActions from "../commons/table-pagination-action-component";

export default function AdminTransactionTable({ plans, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, totalRows }) {
    const TABLE_HEAD = [
        { id: "bankbook_id", label: " Bankbook ID", alignRight: false },
        { id: "client_name", label: " Client Name", alignRight: false },
        { id: "date", label: " Date", alignRight: false },
        { id: "time", label: "Time", alignRight: false },
        { id: "plan_namee", label: "Plan Name", alignRight: false },
        { id: "amount", label: "Amount (N)", alignRight: false },
        { id: "transaction_type", label: "Transaction Type", alignRight: false },
        { id: "source_account", label: "Source Account", alignRight: false },
    ];

    

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };


    const handlePaginationChange = (event, value) => {
        handleChangePage(event, value - 1); // Material-UI Pagination uses 1-based index
    };

    const sortedData = [...plans].sort((a, b) => {
        if (orderBy === "createdAt" ) {
            const dateA = new Date(a[orderBy]);
            const dateB = new Date(b[orderBy]);
            return order === "asc" ? dateA - dateB : dateB - dateA;
        } else if (orderBy === "amount") {
            return order === "asc" ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
        } else if (orderBy === "type" ) {
            return order === "asc"
                ? a[orderBy].localeCompare(b[orderBy])
                : b[orderBy].localeCompare(a[orderBy]);
        }
        return 0;
    });

    return (
        <Card>
            <TableContainer>
                <Table>
                    <GeneralHeader headLabel={TABLE_HEAD} rowCount={plans?.length} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                    <TableBody>
                        {sortedData.map((plan) => {
                            const {
                                createdAt,
                                type,
                                amount,
                                savingsPlan,
                                saver,
                                _id,
                            } = plan;

                            return (
                                <TableRow hover key={_id} tabIndex={-1}>
                                    <TableCell align="left">{saver?.bankbookClientId}</TableCell>
                                    <TableCell align="left">{saver?.firstName} {saver?.lastName}</TableCell>
                                    <TableCell align="left">{formatDate(createdAt)}</TableCell>
                                    <TableCell align="left">{formatTime(createdAt)}</TableCell>
                                    <TableCell align="left">{savingsPlan?.name}</TableCell>
                                    <TableCell align="left">{amount}</TableCell>
                                    <TableCell align="left">{type}</TableCell>
                                    <TableCell align="left">{savingsPlan?.source}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={6} md={4}>
                    <TablePagination
                        rowsPerPageOptions={[20, 30, 40, 50, 60, 70, 80, 90,100, 200, 300, 400, 500, 600, 700, 10000]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions} // Use the custom component
                        
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Pagination
                            count={Math.ceil(totalRows / rowsPerPage)}
                            page={page + 1} // Material-UI Pagination uses 1-based index
                            onChange={handlePaginationChange}
                            color="primary"
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    );
}
