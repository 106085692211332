// material
import {
    Card,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Table,
    Button,
    Stack,
    Pagination,
    Grid
} from "@mui/material";
import GeneralHeader from "src/sections/@dashboard/user/GeneralHeader";
import { useState } from "react";
import { formatDate } from "src/utils/formatTime";
import TablePaginationActions from "../commons/table-pagination-action-component";

export default function AdminPlanTable({ plans, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, totalRows }) {
    const TABLE_HEAD = [
        { id: "bankbook_id", label: "Bankbook ID", alignRight: false },
        { id: "client_name", label: "Client Name", alignRight: false },
        { id: "plan_type", label: "Plan Type", alignRight: false },
        { id: "plan_name", label: "Plan Name", alignRight: false },
        { id: "principal", label: "Principal (N)", alignRight: false },
        { id: "interest", label: "Interest (N)", alignRight: false },
        { id: "tenor", label: "Tenor", alignRight: false },
        { id: "sweep_amount", label: "Sweep Amount", alignRight: false },
        { id: "sweep_frequency", label: "Sweep Frequency", alignRight: false },
        { id: "sweep_date", label: "Sweep Date", alignRight: false },
        { id: "sweep_source", label: "Sweep Source", alignRight: false },
        { id: "rate", label: "Interest Rate (%)", alignRight: false },
        { id: "start_date", label: "Start Date", alignRight: false },
        // { id: "first_payout_date", label: "First Payout Date", alignRight: false },
        { id: "maturity_date", label: "Maturity Date", alignRight: false },
        { id: "status", label: "Status", alignRight: false },
    ];

    const formatStatus = (status) => {
        let bg = '#FAF8FB'
        let color = '#515251'
        if (status == 'active') bg = "#EDFFDD";
        if (status == 'pending') bg = "#EEEEEE";
        if (status == 'cancelled') bg = "#FFE9E9";

        return (<Button style={{ background: `${bg}`, color: `${color}` }}>
            {status}
        </Button>)

    }

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    }


    const handlePaginationChange = (event, value) => {
        handleChangePage(event, value - 1); // Material-UI Pagination uses 1-based index
    };

    const sortedData = [...plans].sort((a, b) => {
        if (orderBy === "startDate" || orderBy === "withdrawalDate") {
            const dateA = new Date(a[orderBy]);
            const dateB = new Date(b[orderBy]);
            return order === "asc" ? dateA - dateB : dateB - dateA;
        } else if (orderBy === "totalDeposit" || orderBy === "interestEarned" || orderBy === "tenor") {
            return order === "asc" ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
        } else if (orderBy === "name" || orderBy === "option" || orderBy === "status") {
            return order === "asc"
                ? a[orderBy].localeCompare(b[orderBy])
                : b[orderBy].localeCompare(a[orderBy]);
        }
        return 0;
    });

    return (
        <Card>
            <TableContainer>
                <Table>
                    <GeneralHeader headLabel={TABLE_HEAD} rowCount={plans?.length} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                    <TableBody>
                        {sortedData.map((plan) => {
                            const {
                                startDate,
                                withdrawalDate,
                                option,
                                name,
                                totalDeposit,
                                interestEarned,
                                tenor,
                                status,
                                _id,
                                sweepAmount,
                                sweepDay,
                                saver,
                                sweepFrequency,
                                source,
                                interestPercentage
                            } = plan;

                            return (
                                <TableRow hover key={_id} tabIndex={-1}>
                                    <TableCell align="left">{saver?.bankbookClientId}</TableCell>
                                    <TableCell align="left">{saver?.firstName} {saver?.lastName}</TableCell>
                                    <TableCell align="left">{option}</TableCell>
                                    <TableCell align="left">{name}</TableCell>
                                    <TableCell align="left">{totalDeposit}</TableCell>
                                    <TableCell align="left">{interestEarned}</TableCell>
                                    <TableCell align="left">{tenor}</TableCell>
                                    <TableCell align="left">{sweepAmount}</TableCell>
                                    <TableCell align="left">{sweepFrequency}</TableCell>
                                    <TableCell align="left">{sweepDay}</TableCell>
                                    <TableCell align="left">{source}</TableCell>
                                    <TableCell align="left">{interestPercentage * 100}</TableCell>
                                    <TableCell align="left">{formatDate(startDate)}</TableCell>
                                    <TableCell align="left">{formatDate(withdrawalDate)}</TableCell>
                                    <TableCell align="left" >{formatStatus(status)}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={6} md={4}>
                    <TablePagination
                        rowsPerPageOptions={[ 20,  30, 40, 50,100, 200,300,400,500,600,10000]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions} // Use the custom component
                        
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Pagination
                            count={Math.ceil(totalRows / rowsPerPage)}
                            page={page + 1} // Material-UI Pagination uses 1-based index
                            onChange={handlePaginationChange}
                            color="primary"
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    );
}
