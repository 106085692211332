import { useEffect, useState } from "react";
import { Container, Grid, Stack, CircularProgress } from "@mui/material";
import AlertDialogSlide from "src/components/SlideInDialog";
import CreateClientForm from "src/components/CreateClientForm";
import { apiFunctions } from "src/api";
import Page from "src/components/Page";
import { AdminDashboardCommonData } from "./commons/admin-dashboard-common-data";
import AdminPlanTable from "./tables/plans";
import { TransactionPlanFilter } from "src/pages/admins/commons/transaction-plan-filter";
import { Modal } from "./commons/modal";

export default function AdminPlan() {
  const [loading, setLoading] = useState(false);
  const [showFormDialog, setShowFormDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [modalClass, setModalClass] = useState('hide-modal');

  const [formData, setFormData] = useState({
    start_date: '',
    end_date: '',
    status: '',
    plan: '',
    page: 20
  });

  const getPlans = async (page, rowsPerPage, query) => {
    try {
      setLoading(true);
      const res = await apiFunctions.getAminReportPlans(page, rowsPerPage, query);
      setData(res?.data);
      setTotalRows(res?.total);
      setLoading(false);
      handleModalClose()
    } catch (e) {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleModalOpen = () => {
    setModalClass('show-modal');
  };

  const handleModalClose = () => {
    setModalClass('hide-modal');
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleDataExport = async () => {
    await apiFunctions.downloadExcelFile(
      'admin-client/reports/plans/export', 'plans'
    )
  }

  const handleFilter = async (event) => {
    event.preventDefault();
    const { plan, status, start_date, end_date } = formData

    const pl = await getPlans(page + 1, formData.page, {
      plan: plan,
      status: status,
      start_date: start_date,
      end_date: end_date
    })

    setRowsPerPage(formData.page)
  }

  useEffect(() => {
    getPlans(page + 1, rowsPerPage);
  }, [page, rowsPerPage]);

  return (
    <Page title="Clients">
      <Container maxWidth="xl">
        <AdminDashboardCommonData handleModal={handleModalOpen} exportData={handleDataExport} />
        <Grid sx={{ mt: 3 }}>
          {loading ? (
            <Grid item xs={12} md={12} lg={12}>
              <Stack
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 10,
                }}
              >
                <CircularProgress />
              </Stack>
            </Grid>
          ) : (
            <AdminPlanTable
              plans={data}
              totalRows={totalRows}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          )}
        </Grid>
        {showFormDialog && (
          <AlertDialogSlide
            isOpen={showFormDialog}
            isClose={() => setShowFormDialog(false)}
            dialogComponent={<CreateClientForm />}
          />
        )}

        <Modal title={'Filter By'} modalDisplayClass={modalClass} onClose={handleModalClose}>
          <TransactionPlanFilter
            handleSubmit={handleFilter}
            data={formData}
            handelInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            cancel={handleModalClose}
          />
        </Modal>

      </Container>
    </Page>
  );
}
