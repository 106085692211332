import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
import { TextareaAutosize, Typography } from "@mui/material";

import {
  Stack,
  TextField,
} from "@mui/material";

// component
import PrimaryButton from "src/components/PrimaryButton";

import { apiFunctions } from "src/api";
import {
  setMessage,
  setShowToast,
  setSuccess,
} from "src/redux/slice/appLoaderSlice";
import { useState } from "react";

// ----------------------------------------------------------------------

export default function MessageNotificationForm() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    message: "",
    title: ""
  })
  const [message, setResponseMessage] = useState('')
  const [success, setSuccessMessage] = useState('')

  const handleInputChange = (e) => {
    setResponseMessage('')
    setSuccessMessage('')
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleFormSubmit =async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      if(!formData.title || !formData.message){
        const msg = "message and title fields must not be empty"
        setResponseMessage(msg)
        setIsLoading(false);
      }else{
        
        const res = await apiFunctions.sendMessage({
          title: formData.title,
          message: formData.message,
        });
        if (res.status) {
          dispatch(setShowToast(true));
          dispatch(setSuccess(true));
          dispatch(setMessage(res?.data?.message));
          setSuccessMessage(res?.message)
          setIsLoading(false);
          setFormData({
            message:"",
            title:""
          })
        } else {
          dispatch(setShowToast(true));
          dispatch(setSuccess(false));
          dispatch(setMessage(res.message));
          setResponseMessage(res?.message)
          setIsLoading(false);
        }
      }

    } catch (e) {      
      dispatch(setShowToast(true));
      dispatch(setMessage(e?.message));
      setResponseMessage(e?.message)
      setSuccess(false);
      setIsLoading(false)
    } 
    setIsLoading(false)
  }

  return (
      <form  >
        <Stack spacing={3}>
          <TextField
            disabled={isLoading}
            name="title"
            value={formData.title}
            fullWidth
            autoComplete="title"
            type="text"
            placeholder="title"
            label="Title/Subject"
            onChange={handleInputChange}
          />

          <TextareaAutosize
            disabled={isLoading}
            autoComplete="current-title"
            style={{ padding: "5px 10px",  }}
            placeholder="Dear {{saver_name}}"
            label="Message"
            minRows={20}
            name="message"
            value={formData.message}
            maxRows={30}
            onChange={handleInputChange}
          />
         <Typography type="p" color={"red"} marginBottom={"30px"}>{message}</Typography>
         <Typography type="p" color={"green"} marginBottom={"30px"}>{success}</Typography>

        </Stack>
       
        <PrimaryButton
          buttonText={"Send Message"}
          loading={isLoading}
          background={"#b7a6f0"}
          onClick={handleFormSubmit}
        />
      </form>
    // </FormikProvider>
  );
}

