// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography, Box, Divider } from "@mui/material";
// utils
import { fShortenNumber } from "../../src/utils/formatNumber";
// component
import Iconify from "../components/Iconify";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",

  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  // justifyContent: "center",
  padding: theme.spacing(5, 5),
  backgroundColor: "white",
  //   width: theme.spacing(38),
  height: theme.spacing(14),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: theme.spacing(6),
  alignItems: "center",
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: "center",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(180deg, rgba(28, 46, 206, 0.2) 0%, rgba(121, 35, 189, 0.2) 100%)`,
}));

// ----------------------------------------------------------------------

export default function DashBoardCard({ icon, amount, title, other }) {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <img
          alt={icon}
          src={`/static/icons/${icon}.svg`}
          width={25}
          height={25}
        />
      </IconWrapperStyle>
      <div style={{ width: "24px" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography
          sx={{
            color: "black",
            fontSize: "26px",
            fontWeight: "bold",
            textAlign: "left",
          }}
        >
          {other ? other : "N" + fShortenNumber(+amount)}
        </Typography>
        <Typography
          sx={{ color: "black", fontSize: "14px", textAlign: "left" }}
        >
          {title}
        </Typography>
      </Box>
    </RootStyle>
  );
}
