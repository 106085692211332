import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
// layouts
// components
import AdminLoginForm from "./authentication/admin-login-form";
import Page from "src/components/Page";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  height: "100%",
}));


const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(5, 0),
}));

// ----------------------------------------------------------------------

export default function AdminLogin() {
  const thisYear = new Date().getFullYear()
  return (
    <RootStyle title="Admin Login | Ajolity">
      <img src="/static/admin-login.png" alt="login" />

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5, alignItems: "center", justifyContent: "center" }}>
            <img
              src="/static/icons/admin-logo.svg"
              alt="login"
            />
          </Stack>

          <AdminLoginForm />
      
          <Typography
            sx={{
              color: "text.black",
              mt: 10,
              fontWeight: "500px",
              textAlign: "center",
            }}
          >
            &copy; Bankvole {thisYear}
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
